<template>
  <Datepicker
    v-model="range"
    range
    :minDate="minDate || fixedMinDate"
    :enableTimePicker="false"
    :disabledDates="disabledDates"
    :format="formatDate"
    :monthChangeOnScroll="false"
    :action-row-component="actionRow"
    :customProps="customProps"
    :inline="inline"
    :fixedStart="fixedStart"
    :disabled="disabled"
    :ignoreRentId="ignoreRentId"
    :startDate="startDate"
    ><template #dp-input="{ value }">
      <n-input
        :value="value"
        type="text"
        :placeholder="t('statsAndCalendar.form.placeholders.select')"
      >
        <template #prefix>
          <n-icon>
            <CalendarIcon />
          </n-icon> </template></n-input></template
  ></Datepicker>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  defineAsyncComponent,
  reactive,
} from "vue";
import responsive from "@/mixins/responsive";
import { getHistory } from "@/shared/calendar";
import { formatDates } from "@/shared/utils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { CalendarClearOutline as CalendarIcon } from "@vicons/ionicons5";
import { NInput, NIcon } from "naive-ui";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

const ActionRow = defineAsyncComponent(() => import("./ActionRowCustom.vue"));

export default defineComponent({
  mixins: [responsive],
  name: "DateSelector",
  components: { Datepicker, CalendarIcon, NInput, NIcon },
  props: {
    publication: { type: Object, required: true },
    value: { type: Object },
    inline: { type: Boolean, default: false },
    fixedStart: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    ignoreRentId: { type: String, default: null },
    minDate: { type: Date, default: null },
    startDate: { type: Date, default: null },
  },
  emits: ["update:value"],
  data() {
    return {
      themeOverrides,
    };
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const rangeRef = ref([props.value?.start, props.value?.end]);
    const publicationRef = ref(props.publication);
    const actionRow = computed(() => ActionRow);
    const history = computed(() => {
      return getHistory(publicationRef.value, props.ignoreRentId);
    });

    const fixedMinDate = new Date();
    fixedMinDate.setDate(fixedMinDate.getDate() + 1);

    watch(rangeRef, (rangeRef) => {
      if (rangeRef === null) {
        emit("update:value", null);
      } else {
        emit("update:value", {
          start: new Date(rangeRef[0].setHours(0, 0, 0, 0)),
          end: new Date(rangeRef[1].setHours(0, 0, 0, 0)),
        });
      }
    });

    return {
      t,
      fixedMinDate,
      range: rangeRef,
      publicationRef,
      formatDate: (date) => {
        return formatDates(date[0], date[1]);
      },
      disabledDates: computed(() => {
        return history.value.map((a) => a.date);
      }),
      actionRow,
      customProps: reactive({
        history: history,
      }),
    };
  },
});
</script>

<style scoped>
.dp__theme_light {
  --dp-background-color: v-bind(themeOverrides.common.whiteColor);
  --dp-text-color: v-bind(themeOverrides.common.darkColor);
  --dp-hover-color: v-bind(themeOverrides.common.dangerColor);
  --dp-hover-text-color: v-bind(themeOverrides.common.darkColor);
  --dp-hover-icon-color: v-bind(themeOverrides.common.iconColor);
  --dp-primary-color: v-bind(themeOverrides.common.primaryColor);
  --dp-primary-text-color: v-bind(themeOverrides.common.primaryColor);
  --dp-secondary-color: v-bind(themeOverrides.common.secondaryColor);
  --dp-border-color: v-bind(themeOverrides.common.borderColor);
  --dp-border-color-hover: v-bind(themeOverrides.common.borderHoverColor);
  --dp-disabled-color: v-bind(themeOverrides.common.disabledColor);
  --dp-scroll-bar-background: v-bind(themeOverrides.common.hoverColor);
  --dp-scroll-bar-color: v-bind(themeOverrides.common.iconColor);
  --dp-success-color: v-bind(themeOverrides.common.successColor);
  --dp-success-color-disabled: v-bind(
    themeOverrides.common.successDisabledColor
  );
  --dp-icon-color: v-bind(themeOverrides.common.iconColor);
  --dp-danger-color: v-bind(themeOverrides.common.dangerColor);
}
</style>
