import { RentState, PublicationState, MaintenanceState } from "@/models/index";
import {
    eachDayOfInterval,
    isSameDay,
    addDays,
    isBefore,
  } from "date-fns";

const EventType = {
    CREATED: "CREATED",
    RESERVED: "RESERVED",
    RENTED: "RENTED",
    MAINTENANCE: "MAINTENANCE",
    FINISHED: "FINISHED",
    DELAYED: "DELAYED",
    ARBITRATION_IN_PROCESS: "ARBITRATION_IN_PROCESS"
  };

const getHistory = (publication, ignoreRentId) =>{
    let history = [];

      if (publication.createdAt) {
        history.push({
          date: publication.createdAt,
          event: EventType.CREATED,
        });
      }

      publication.rents?.items?.forEach((rent) => {
        switch (rent.state) {
          case RentState.PENDING:
          case RentState.ARBITRATION_IN_PROCESS: {
            if(rent.id && ignoreRentId && rent.id === ignoreRentId){
              break;
            }
            eachDayOfInterval(rent.requestedRangeOfDates).forEach((day) => {
              history.push({ date: day, event: rent.state === RentState.PENDING ? EventType.RESERVED : EventType.ARBITRATION_IN_PROCESS});
            });
            break;
          }
          case RentState.LOST:
          case RentState.IN_PROCESS: {
            if(rent.id && ignoreRentId && rent.id === ignoreRentId){
              break;
            }
            if (isSameDay(rent.executedRangeOfDates.start, new Date())) {
              history.push({
                date: rent.executedRangeOfDates.start,
                event: EventType.RENTED,
              });
            } else {
              eachDayOfInterval({
                start: rent.executedRangeOfDates.start,
                end: new Date(),
              }).forEach((day) => {
                history.push({ date: day, event: isBefore(day, addDays(rent.requestedRangeOfDates.end,1))?EventType.RENTED:EventType.DELAYED });
              });
            }
            const tomorrow = addDays(new Date(), 1);
            if (isSameDay(tomorrow, rent.requestedRangeOfDates.end)) {
              history.push({
                date: rent.requestedRangeOfDates.end,
                event: EventType.RESERVED,
              });
            } else if (isBefore(tomorrow, rent.requestedRangeOfDates.end)) {
              eachDayOfInterval({
                start: tomorrow,
                end: rent.requestedRangeOfDates.end,
              }).forEach((day) => {
                history.push({ date: day, event: EventType.RESERVED });
              });
            }

            break;
          }
          case RentState.FINISHED:
          case RentState.RETURNED: {
            eachDayOfInterval(rent.executedRangeOfDates).forEach((day) => {
              history.push({ date: day, event: EventType.RENTED });
            });
            break;
          }
        }
      });

      publication.maintenances
        ?.filter((maintenance) => maintenance.state != MaintenanceState.DELETED)
        .forEach((maintenance) => {
          eachDayOfInterval(maintenance.rangeOfDates).forEach((day) => {
            history.push({ date: day, event: EventType.MAINTENANCE });
          });
        });

      if (publication.state === PublicationState.INACTIVE) {
        history.push({
          date: publication.updatedAt,
          event: EventType.FINISHED,
        });
      }

      return history;
}

export { getHistory, EventType}