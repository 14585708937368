<template>
  <n-divider title-placement="left">{{ t("commons.labels.payment", 2) }}</n-divider>
  <n-skeleton text :repeat="4" v-if="!dropinShow" />
  <n-progress
    type="line"
    :percentage="100"
    processing
    :height="16"
    :show-indicator="false"
    class="my-5"
    v-if="processingShow"
  />
  <div v-else>
    <div ref="dropInContainerRef" id="dropin-container"></div>
    <div>
      <n-button
        class="mr-2"
        v-if="backAction"
        size="small"
        type="default"
        @click="backAction"
      >
        {{ t("commons.actions.back") }}
      </n-button>
      <n-button
        v-if="dropinShow && mode !== 'VAULT'"
        ref="btnPay"
        class="ml-2 mt-4"
        type="primary"
        size="small"
      >
        <span>{{ t("commons.actions.pay") }}</span>
      </n-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import dropin from "braintree-web-drop-in";
import { NSkeleton, NProgress, NDivider, NButton } from "naive-ui";
import { useI18n } from "vue-i18n";

export default {
  name: "PaymentDropIn",
  components: { NSkeleton, NProgress, NDivider, NButton },
  props: {
    amount: { type: Number, required: true },
    taxAmount: { type: Number, required: true },
    rent: { type: Object, required: false, default: null },
    publication: { type: Object, required: false, default: null },
    backAction: { type: Function, default: null },
    card: { type: Object, required: false, default: null },
    mode: {
      type: String,
      required: true,
      default: null,
      validator(value) {
        return ["VAULT", "PAYMENT", "STORE_REF", "HOLD"].includes(value);
      },
    },
    paymentType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return ["INITIAL", "FOR_EXTRA_DAYS", "FOR_DELAY_RETURN", "FOR_DEPOSIT"].includes(
          value
        );
      },
    },
  },
  emits: ["sucess", "error-process", "error-init", "update:card"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();

    const dropinShowRef = ref(false);
    const processingShowRef = ref(false);
    const btnPayRef = ref(null);
    const dropInContainerRef = ref(null);
    const dropinInstanceRef = ref(null);
    const lockVaultManagerRef = ref(false);

    const processPayment = async (reqPaymentErr, payload) => {
      emit("update:card", payload);
      if (reqPaymentErr) {
        console.error("Error DropIn:", reqPaymentErr);
        emit("error-process", reqPaymentErr);
      } else {
        if (props.mode !== "VAULT") {
          processingShowRef.value = true;
          await store.dispatch("app/lockUI");
          store
            .dispatch("payments/Process", {
              rent: props.rent,
              publication: props.publication,
              paymentMethod: payload,
              amount: props.amount,
              taxAmount: props.taxAmount,
              paymentType: props.paymentType,
              mode: props.mode,
            })
            .then((response) => {
              emit("sucess", response);
            })
            .catch((txErr) => {
              console.error("Error DropIn:", txErr.message);
              emit("error-process", txErr);
            })
            .finally(async () => {
              processingShowRef.value = false;
              await store.dispatch("app/unlockUI");
            });
        } else {
          emit("sucess");
        }
      }
    };

    const initDropIn = (clientToken) => {
      console.debug("lockVaultManagerRef.value", lockVaultManagerRef.value);
      dropinShowRef.value = true;
      dropInContainerRef.value.innerHTML = "";
      dropin.create(
        {
          authorization: clientToken,
          selector: "#dropin-container",
          vaultManager: !lockVaultManagerRef.value,
          dataCollector: true,
        },
        function (err, dropinInstance) {
          if (err) {
            // Handle any errors that might've occurred when creating Drop-in
            console.error(err);
            emit("error-init", err);
            return;
          }
          btnPayRef.value.$el.addEventListener("click", function () {
            dropinInstance.requestPaymentMethod(processPayment);
          });
          dropinInstanceRef.value = dropinInstance;
        }
      );
    };

    const getToken = async (paymentCustomerId) => {
      return await store.dispatch("payments/GetClientToken", paymentCustomerId);
    };

    const registUserInPaymentPlatform = async (user) => {
      const paymentCustomerId = await store.dispatch(
        "payments/CreateCustomerId",
        store.getters["auth/email"]
      );

      await store.dispatch("user/updateUser", {
        ...user,
        paymentCustomerId: paymentCustomerId,
      });
      return paymentCustomerId;
    };
    onUnmounted(async () => {
      dropinInstanceRef.value?.teardown(function (err) {
        if (err) {
          console.error("An error occurred during teardown:", err);
        }
      });
    });
    onMounted(async () => {
      //Se obtiene el user desde el store o desde db si es que aun no se carga en el store
      const user =
        store.state.user.userFromDB ||
        (await store.dispatch("user/getUserById", store.getters["auth/user_id"]));
      //Se obtiene el id del usuario en la plataforma de pago de la bd, si no existe, se registra en la plataforma de pagos, se guarda en bd y se usa
      const paymentCustomerId =
        user.paymentCustomerId || (await registUserInPaymentPlatform(user));
      //Se verifica si puede manejar el vault
      lockVaultManagerRef.value =
        (await store.dispatch("rent/getRentsThatBlockVault")).length > 0;
      //Se obtiene el token y se inica el dropIn
      getToken(paymentCustomerId).then(initDropIn);
    });

    return {
      t,
      dropinShow: dropinShowRef,
      btnPay: btnPayRef,
      processingShow: processingShowRef,
      dropInContainerRef,
    };
  },
};
</script>
