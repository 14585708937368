<template>
  <div>
    <check-verified-profile />
    <n-grid cols="1 s:8" responsive="screen" style="padding-top: 30px">
      <n-gi span="1 s:5" offset="0 s:1">
        <n-space vertical :class="!isMobile ? 'stepper' : ''">
          <n-steps
            vertical
            :current="current"
            :status="currentStatus"
            class="ml-2"
          >
            <n-step
              :title="t('rent.createUpdate.steps.one.title')"
              class="mr-4"
            >
              <div class="n-step-description pt-4" v-if="current === 1">
                <range-dates-selector
                  v-if="publication"
                  :publication="publication"
                  :fixedStart="rangeOfDatesFixedStart"
                  v-model:value="rent.requestedRangeOfDates"
                  :disabled="rangeOfDatesInputDisabled"
                  @rangechanged="onRequestedRangeOfDatesChange"
                  :ignoreRentId="rent.id"
                />
                <delivery-info
                  v-if="publication"
                  :publication="publication"
                  :disabled="deliveryInfoInputDisabled"
                  v-model:value="rent.deliveryInfo"
                />
                <navigations-button-section
                  class="pt-4"
                  :current="current"
                  :step="1"
                  isInitStep
                  :totalSteps="totalSteps"
                  @clickNext="next"
                  :canContinue="canContinue"
                />
              </div>
            </n-step>
            <n-step
              :title="t('rent.createUpdate.steps.two.title')"
              class="mr-4"
            >
              <div class="n-step-description pt-4" v-if="current === 2">
                <rent-resumen
                  v-if="publication"
                  :publicationTitle="publication.title"
                  :reservedDates="rent.requestedRangeOfDates"
                  :deliveryInfo="
                    showDeliveryInfoInResumen ? rent.deliveryInfo : null
                  "
                  :executedDates="
                    showExecutedDaysInResumen ? rent.executedRangeOfDates : null
                  "
                  :reservedDaysCount="rent.requestedDays"
                  :executedDaysCount="
                    showExecutedDaysInResumen ? rent.executedDays : null
                  "
                  :delayedDaysCount="
                    showDelayedDaysCountInResumen
                      ? rent.executedDays - rent.requestedDays
                      : null
                  "
                  fullscreen
                />
                <price-details
                  :rent="rent"
                  :loading="updatingTotals"
                  fullscreen
                  @onmounted="updateTotals"
                />
                <payment-drop-in
                  v-if="
                    !updatingTotals &&
                    (rent.pendingToPay > 0 || rent.state === 'DEPOSIT_PENDING')
                  "
                  :amount="
                    rent.state === 'DEPOSIT_PENDING'
                      ? rent.deposit
                      : rent.pendingToPay
                  "
                  :taxAmount="
                    rent.state === 'DEPOSIT_PENDING'
                      ? 0
                      : rent.taxOfPendingToPay
                  "
                  :rent="rent"
                  :publication="publication"
                  @sucess="next"
                  @error-process="onPaymentError(false)"
                  @error-init="onPaymentError(true)"
                  :backAction="canGoBack ? prev : null"
                  :paymentType="paymentType"
                  v-model:card="cardInfo"
                  :mode="paymentDropInMode"
                />
                <navigations-button-section
                  v-if="
                    !updatingTotals &&
                    rent.pendingToPay <= 0 &&
                    rent.state !== 'DEPOSIT_PENDING'
                  "
                  :current="current"
                  :step="2"
                  :totalSteps="totalSteps"
                  @clickNext="updateRangeOfDates"
                  @clickBack="prev"
                  :hideBackButton="!canGoBack"
                  :nextButtonText="
                    t('commons.actions.update', {
                      reference: t('commons.labels.rent', 1),
                    })
                  "
                  nextButtonType="success"
                />
              </div>
            </n-step>
            <n-step
              :title="t('rent.createUpdate.steps.three.title')"
              class="mr-4"
            >
              <div class="n-step-description pt-4" v-if="current === 3">
                <n-alert
                  v-if="rentId"
                  :title="
                    t('rent.createUpdate.steps.dialogs.changesRegistered.title')
                  "
                  type="success"
                >
                  {{
                    t(
                      "rent.createUpdate.steps.dialogs.changesRegistered.content"
                    )
                  }}
                  <br />
                  {{
                    t(
                      "rent.createUpdate.steps.dialogs.changesRegistered.footer"
                    )
                  }}
                </n-alert>
                <n-alert
                  v-else
                  :title="
                    t('rent.createUpdate.steps.dialogs.paymentDone.title')
                  "
                  type="success"
                >
                  {{ t("rent.createUpdate.steps.dialogs.paymentDone.content") }}
                  <br />
                  {{ t("rent.createUpdate.steps.dialogs.paymentDone.footer") }}
                </n-alert>
                <navigations-button-section
                  class="mt-4"
                  :current="current"
                  :step="3"
                  :totalSteps="totalSteps"
                  @clickNext="next"
                  @clickBack="prev"
                  isFinalStep
                  hideBackButton
                />
              </div>
            </n-step>
          </n-steps>
        </n-space>
      </n-gi>
      <n-gi span="0 s:2">
        <publication-resumen
          v-if="publication"
          :publication="publication"
          showDefaultImage
          :bordered="false"
          :showDeliveryInfo="showDeliveryInfoInResumen"
          :showLocation="showDeliveryInfoInResumen"
          :showSpecialOffers="showSpecialOffersInPublicationResume"
          vertical
          showPrice
        />
      </n-gi>
    </n-grid>
    <footer-mobile
      v-if="isMobile"
      haveDetails
      @expand="onExpandFooterMobileChange"
      :detailDrawerHeight="`${(parseInt(windowsHeight) - 80).toString()}px`"
    >
      <publication-resumen
        v-if="publication"
        :publication="publication"
        showDefaultImage
        :bordered="false"
        :showDeliveryInfo="showDeliveryInfoInResumen"
        :showLocation="showDeliveryInfoInResumen"
        :showSpecialOffers="showSpecialOffersInPublicationResume"
        vertical
        showPrice
      />
    </footer-mobile>
  </div>
</template>

<script>
import NavigationsButtonSection from "@/components/publication/maintainers/NavigationsButton.vue";
import PublicationResumen from "@/components/publication/PublicationResumen.vue";
import RangeDatesSelector from "@/components/rent/RangeDatesSelector.vue";
import DeliveryInfo from "@/components/rent/DeliveryInfo.vue";
import RentResumen from "@/components/rent/Resumen.vue";
import PriceDetails from "@/components/rent/PriceDetails.vue";
import CheckVerifiedProfile from "@/components/myProfile/CheckVerifiedProfile.vue";
import PaymentDropIn from "@/components/PaymentDropIn.vue";
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import responsive from "@/mixins/responsive";
import {
  NSpace,
  NSteps,
  NStep,
  NGrid,
  NGi,
  useMessage,
  useDialog,
  NAlert,
} from "naive-ui";
import FooterMobile from "@/components/FooterMobile.vue";
import { quantityDaysInRange, parseStringToDate } from "@/shared/utils";
import { differenceInMonths, differenceInCalendarDays } from "date-fns";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RentMain",
  mixins: [responsive],
  props: {
    publicationId: String,
    rentId: String,
  },
  components: {
    NavigationsButtonSection,
    PublicationResumen,
    NSpace,
    NSteps,
    NStep,
    NGrid,
    NGi,
    FooterMobile,
    RangeDatesSelector,
    DeliveryInfo,
    PriceDetails,
    RentResumen,
    PaymentDropIn,
    NAlert,
    CheckVerifiedProfile,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const message = useMessage();
    const dialog = useDialog();
    const router = useRouter();
    const currentRef = ref(1);
    const totalStepsRef = ref(3);
    const currentStatusRef = ref("process");
    const store = useStore();
    const rentRef = ref({
      id: null,
      state: "UNPAID",
      publicationID: null,
      requestedDays: 0,
      daysWithDiscount: 0,
      pricePerDay: 0,
      pricePerDayDiscounted: 0,
      baseCost: 0,
      discount: 0,
      tax: 0,
      totalCost: 0,
      renterServiceFee: 0,
      deposit: 0,
      paymentsInfo: null,
      requestedRangeOfDates: null,
      deliveryInfo: {
        renterWillPickItUp: true,
      },
      user: null,
      relatedUsers: [],
      _version: null,
      rangeOfDatesHistory: null,
    });
    const publicationRef = ref(null);
    const footerMobileExpanded = ref(false);
    const updatingTotalsRef = ref(true);
    const rangeOfDatesInputDisabledRef = ref(false);
    const deliveryInfoInputDisabledRef = ref(false);
    const rangeOfDatesFixedStartRef = ref(null);
    const canGoBackRef = ref(true);
    const showExecutedDaysInResumenRef = ref(false);
    const showDeliveryInfoInResumenRef = ref(true);
    const showDelayedDaysCountInResumenRef = ref(false);
    const showSpecialOffersInPublicationResumeRef = ref(true);
    const showPendingForPaymentInfoRef = ref(false);
    const showDepositInfoInPriceResumenRef = ref(true);
    const originalRentRef = ref(null);
    const isOtherRentInProcess = ref(false);
    const paymentDropInModeRef = ref("STORE_REF");
    const cardInfoRef = ref(null);

    const initNewRent = async () => {
      //Load Publication data
      publicationRef.value = await store.dispatch(
        "maintainer_publication/getPublicationByIdForRent",
        props.publicationId
      );

      // Set initial info from publication
      rentRef.value.publicationID = publicationRef.value.id;
      rentRef.value.pricePerDay = publicationRef.value.prices.pricePerDay;
      rentRef.value.deposit = publicationRef.value.depositRequired;
      rentRef.value.deliveryInfo.locationInfo =
        publicationRef.value.locationInfo;
      //Set users related
      rentRef.value.user = store.getters["auth/user_id"];
      rentRef.value.relatedUsers = [
        store.getters["auth/user_id"],
        publicationRef.value.user,
      ];
    };
    const loadPreviousRent = async (rentId = null) => {
      //Load Rent data
      rentRef.value = await store.dispatch(
        "rent/getRentById",
        rentId || props.rentId
      );

      if (rentRef.value.user !== store.getters["auth/user_id"]) {
        message.warning(t("rent.createUpdate.steps.messages.wrongUser"));
        router.push({
          name: "view-rent-detail",
          params: { id: rentRef.value.id },
        });
      }

      publicationRef.value = rentRef.value.publication;
      rentRef.value.publication = null;
      originalRentRef.value = { ...rentRef.value };

      const isCancelationFree = await store.dispatch("rent/isCancelationFree", {
        rent: originalRentRef.value,
        publication: publicationRef.value,
      });

      isOtherRentInProcess.value = await store.dispatch(
        "rent/isOtherRentInProcess",
        {
          rentId: originalRentRef.value.id,
          publicationId: publicationRef.value.id,
        }
      );
      console.debug("isOtherRentInProcess", isOtherRentInProcess.value);

      switch (rentRef.value.state) {
        // UNPAID: Permite editar todo
        case "UNPAID":
          rangeOfDatesInputDisabledRef.value = false;
          deliveryInfoInputDisabledRef.value = false;
          break;
        // IN_PROCESS: Solo se puede cambiar la fecha de finalizacion siempre y cuando no este fuera de tiempo
        case "IN_PROCESS":
          rangeOfDatesInputDisabledRef.value = false;
          deliveryInfoInputDisabledRef.value = true;
          showPendingForPaymentInfoRef.value = true;
          rangeOfDatesFixedStartRef.value = parseStringToDate(
            rentRef.value.requestedRangeOfDates.start
          );

          // Se calculan los dias ejecutados hasta el momento y se verifica si es que esta pasado de la fecha de retorno
          rentRef.value.executedDays = quantityDaysInRange(
            rentRef.value.executedRangeOfDates.start,
            new Date()
          );
          if (rentRef.value.executedDays > rentRef.value.requestedDays) {
            message.warning(
              t("rent.createUpdate.steps.messages.returnDelayed")
            );
            router.push({
              name: "view-rent-detail",
              params: { id: rentRef.value.id },
            });
          }
          // Se cambia el modo del dropin para procesar el pago de forma inmediata
          paymentDropInModeRef.value = "PAYMENT";
          break;
        // PENDING: Solo puede aumentar la cantidad de dias
        case "PENDING":
          rangeOfDatesInputDisabledRef.value =
            rentRef.value.rangeOfDatesHistory.filter(
              (entry) => entry.type === "MODIFICATION"
            ).length > 0;
          deliveryInfoInputDisabledRef.value = true;
          showPendingForPaymentInfoRef.value = true;

          // Se evaluan los datos de cancelacion gratis para ver si se puede cambiar la fecha de inicio
          if (!isCancelationFree && !isOtherRentInProcess.value) {
            rangeOfDatesFixedStartRef.value = parseStringToDate(
              rentRef.value.requestedRangeOfDates.start
            );
          }

          // Se muestra mensaje al usuario indicando la razon por la que no puede cambiar las fechas
          if (rangeOfDatesInputDisabledRef.value) {
            dialog.info({
              title: t(
                "rent.createUpdate.steps.dialogs.maxModificationsReached.title"
              ),
              content: t(
                "rent.createUpdate.steps.dialogs.maxModificationsReached.title"
              ),
              positiveText: t("commons.confirmation.ok"),
            });
          }
          break;
        // PENDING_FOR_PAY_EXTRA_DAYS  Solo puede pagar el saldo restante o el monto del deposito
        case "PENDING_FOR_PAY_EXTRA_DAYS":
          rangeOfDatesInputDisabledRef.value = true;
          deliveryInfoInputDisabledRef.value = true;
          currentRef.value = 2;
          canGoBackRef.value = false;
          showExecutedDaysInResumenRef.value = true;
          showDeliveryInfoInResumenRef.value = false;
          showDelayedDaysCountInResumenRef.value = true;
          showSpecialOffersInPublicationResumeRef.value = false;
          showPendingForPaymentInfoRef.value = true;
          showDepositInfoInPriceResumenRef.value = false;
          // Se cambia el modo del dropin para procesar el pago de forma inmediata
          paymentDropInModeRef.value = "PAYMENT";
          break;
        // PENDING_FOR_CHANGE_PAYMENT_METHOD || DEPOSIT_PENDING: Solo puede pagar el rent o deposito
        case "DEPOSIT_PENDING":
        case "PENDING_FOR_CHANGE_PAYMENT_METHOD":
          rangeOfDatesInputDisabledRef.value = true;
          deliveryInfoInputDisabledRef.value = true;
          currentRef.value = 2;
          canGoBackRef.value = false;
          showPendingForPaymentInfoRef.value = true;

          // Se cambia el modo del dropin para procesar el pago de forma inmediata
          paymentDropInModeRef.value =
            rentRef.value.state === "DEPOSIT_PENDING" ? "HOLD" : "PAYMENT";
          break;
        // Resto de casos redireccionar a detail
        case "RETURNED":
        case "FINISHED":
        case "ARBITRATION_IN_PROCESS":
        case "LOST":
        default:
          message.warning(
            t("rent.createUpdate.steps.messages.rentCantBeModified", {
              status: t(`rent.states.${rentRef.value.state}`),
            })
          );
          router.push({
            name: "view-rent-detail",
            params: { id: rentRef.value.id },
          });
      }
    };

    onMounted(async () => {
      await store.dispatch("app/lockUI");

      if (props.rentId && props.rentId !== "") {
        await loadPreviousRent();
      } else {
        await initNewRent();
      }

      await store.dispatch("app/unlockUI");
    });

    const canContinueRef = computed(() => {
      if (canContinueRef.value !== 1) {
        return true;
      }
      return (
        rentRef.value.requestedRangeOfDates !== null &&
        rentRef.value.deliveryInfo !== null &&
        (!rangeOfDatesInputDisabledRef.value ||
          !deliveryInfoInputDisabledRef.value)
      );
    });

    return {
      t,
      current: currentRef,
      currentStatus: currentStatusRef,
      rent: rentRef,
      publication: publicationRef,
      canContinue: canContinueRef,
      paymentDropInMode: paymentDropInModeRef,
      cardInfo: cardInfoRef,
      next: async () => {
        if (canContinueRef.value) {
          await store.dispatch("app/lockUI");
          if (currentRef.value === totalStepsRef.value) {
            router.push({ name: "my-rents" });
          }
          if (currentRef.value === null) {
            currentRef.value = 1;
          } else {
            currentRef.value++;
          }
          await store.dispatch("app/unlockUI");
        }
      },
      prev: () => {
        if (currentRef.value === 1) currentRef.value = totalStepsRef.value;
        else currentRef.value--;
      },
      totalSteps: totalStepsRef,
      footerMobileExpanded,
      onExpandFooterMobileChange: (expanded) =>
        (footerMobileExpanded.value = expanded),
      onRequestedRangeOfDatesChange: async () => {
        console.debug(
          "onRequestedRangeOfDatesChange - started",
          rentRef.value.requestedRangeOfDates
        );

        //Por alguna razon, al cargar esta vista, el calendario fija como fecha de inicio la fecha actual pero despues la cambia
        //Y eso genera un calculo errado mostrando un aviso incorrecto, se agrega esta validacion para evitar el problema
        const requestedDays = rentRef.value.requestedRangeOfDates
          ? quantityDaysInRange(
              rangeOfDatesFixedStartRef.value ||
                rentRef.value.requestedRangeOfDates.start,
              rentRef.value.requestedRangeOfDates.end
            )
          : 0;

        const originalRequestedDays = originalRentRef.value
          ?.requestedRangeOfDates
          ? quantityDaysInRange(
              originalRentRef.value.requestedRangeOfDates.start,
              originalRentRef.value.requestedRangeOfDates.end
            )
          : 0;
        console.debug("rentRef.value.state", rentRef.value.state);
        console.debug("requestedDays", requestedDays);
        console.debug("originalRequestedDays", originalRequestedDays);
        if (
          rentRef.value.state === "IN_PROCESS" &&
          requestedDays < originalRequestedDays
        ) {
          dialog.warning({
            title: t(
              "rent.createUpdate.steps.dialogs.checkDatesOnlyCanAddDays.title"
            ),
            closable: false,
            maskClosable: false,
            content: t(
              "rent.createUpdate.steps.dialogs.checkDatesOnlyCanAddDays.content"
            ),
            positiveText: t("commons.actions.continue"),
          });
          rentRef.value.requestedRangeOfDates = null;
        }
        if (
          rentRef.value.state === "PENDING" &&
          requestedDays < originalRequestedDays
        ) {
          if (
            rentRef.value.paymentsInfo.filter(
              (payment) =>
                payment.status === "SUBMITTED_FOR_SETTLEMENT" ||
                payment.status === "AUTHORIZED"
            ).length > 0
          ) {
            dialog.warning({
              title: t(
                "rent.createUpdate.steps.dialogs.checkDatesPaymentsInProcess.title"
              ),
              closable: false,
              maskClosable: false,
              content: t(
                "rent.createUpdate.steps.dialogs.checkDatesPaymentsInProcess.content"
              ),
              positiveText: t("commons.actions.continue"),
            });
            rentRef.value.requestedRangeOfDates = null;
          } else if (rangeOfDatesFixedStartRef.value) {
            dialog.warning({
              title: t(
                "rent.createUpdate.steps.dialogs.checkDatesNoCancelationFree.title"
              ),
              closable: false,
              maskClosable: false,
              content: t(
                "rent.createUpdate.steps.dialogs.checkDatesNoCancelationFree.content"
              ),
              positiveText: t("commons.actions.continue"),
            });
            rentRef.value.requestedRangeOfDates = null;
          }
        }
        if (
          rentRef.value.state === "PENDING" &&
          requestedDays !== originalRequestedDays &&
          isOtherRentInProcess.value
        ) {
          dialog.warning({
            title: t(
              "rent.createUpdate.steps.dialogs.checkDatesCanChangeDays.title"
            ),
            closable: false,
            maskClosable: false,
            content: t(
              "rent.createUpdate.steps.dialogs.checkDatesCanChangeDays.content"
            ),
            positiveText: t("commons.actions.continue"),
          });
          rentRef.value.requestedRangeOfDates = null;
        }
        //Get requested Days
        rentRef.value.requestedDays = rentRef.value.requestedRangeOfDates
          ? quantityDaysInRange(
              rentRef.value.requestedRangeOfDates.start,
              rentRef.value.requestedRangeOfDates.end
            )
          : 0;

        //Get Discounted Price for day
        rentRef.value.pricePerDayDiscounted = 0;
        if (
          rentRef.value.requestedDays > 0 &&
          publicationRef.value.prices.offerDiscount
        ) {
          const qtyMonths = differenceInMonths(
            rentRef.value.requestedRangeOfDates.end,
            rentRef.value.requestedRangeOfDates.start
          );
          const qtyWeeks = rentRef.value.requestedDays / 7;
          if (qtyMonths >= 1) {
            rentRef.value.pricePerDayDiscounted =
              publicationRef.value.prices.discountedPricePerDayForMonths;
          } else if (qtyWeeks >= 1) {
            rentRef.value.pricePerDayDiscounted =
              publicationRef.value.prices.discountedPricePerDayForWeeks;
          }
        }

        //Verificacion para cobrar inmediatamente si la fecha de inicio del rent es en menos de 24 horas
        if (
          rentRef.value.state === "UNPAID" ||
          rentRef.value.state === "PENDING"
        ) {
          const daysBeforeStartRent = differenceInCalendarDays(
            rangeOfDatesFixedStartRef.value ||
              rentRef.value.requestedRangeOfDates.start,
            new Date()
          );
          console.debug("daysBeforeStartRent", daysBeforeStartRent);
          paymentDropInModeRef.value =
            daysBeforeStartRent <= 1 ? "HOLD" : "STORE_REF";
        }
        console.debug("onRequestedRangeOfDatesChange - end");
      },
      updateTotals: async () => {
        updatingTotalsRef.value = true;

        rentRef.value = await store.dispatch("rent/calculateTotals", {
          rent: rentRef.value,
          publication: publicationRef.value,
          originalRangeOfDates: originalRentRef.value?.requestedRangeOfDates,
        });

        updatingTotalsRef.value = false;
      },
      updatingTotals: updatingTotalsRef,
      rangeOfDatesInputDisabled: rangeOfDatesInputDisabledRef,
      deliveryInfoInputDisabled: deliveryInfoInputDisabledRef,
      rangeOfDatesFixedStart: rangeOfDatesFixedStartRef,
      canGoBack: canGoBackRef,
      showExecutedDaysInResumen: showExecutedDaysInResumenRef,
      showDeliveryInfoInResumen: showDeliveryInfoInResumenRef,
      showDelayedDaysCountInResumen: showDelayedDaysCountInResumenRef,
      showSpecialOffersInPublicationResume:
        showSpecialOffersInPublicationResumeRef,
      showPendingForPaymentInfo: showPendingForPaymentInfoRef,
      showDepositInfoInPriceResumen: showDepositInfoInPriceResumenRef,
      onPaymentError: () => {
        dialog.warning({
          title: t("rent.createUpdate.steps.dialogs.paymentError.title"),
          closable: false,
          maskClosable: false,
          content: t("rent.createUpdate.steps.dialogs.paymentError.content"),
          positiveText: t("commons.confirmation.ok"),
          onPositiveClick: () => {
            currentRef.value = 1;
          },
        });
      },
      updateRangeOfDates: async () => {
        await store.dispatch("app/lockUI");
        if (rentRef.value.pendingToPay < 0) {
          const payment = rentRef.value.paymentsInfo[0];
          let rentFromRefund;
          if (payment.transactionId) {
            rentFromRefund = await store.dispatch("payments/Refund", {
              rentId: rentRef.value.id,
              transactionId: payment.transactionId,
              amount: rentRef.value.pendingToPay * -1,
            });
          } else {
            payment.amount = rentRef.value.totalCost;
            payment.balance = rentRef.value.totalCost;
            payment.taxAmount = rentRef.value.tax;
            rentFromRefund = await store.dispatch("rent/modifyPaymentInRent", {
              rent: rentRef.value,
              payment: payment,
            });
          }
          rentRef.value.totalPayed = rentFromRefund.totalPayed;
          rentRef.value.paymentsInfo = rentFromRefund.paymentsInfo;
          rentRef.value._version = rentFromRefund._version;
        }
        await store.dispatch("rent/updateRequestedRangeOfDates", rentRef.value);
        currentRef.value++;
        //Enviamos notificacion al owner
        store.dispatch("notifications/FireNotification", {
          userId: publicationRef.value.user,
          fromUserId: publicationRef.value.user,
          title: t("rent.createUpdate.steps.notifications.rentUpdated.title"),
          message: `${t(
            "rent.createUpdate.steps.notifications.rentUpdated.content"
          )} ${publicationRef.value.title}.`,
          avatar: {
            publicationID: publicationRef.value.id,
            publicationUser: publicationRef.value.user,
            defaultImage: publicationRef.value.orderPhotos[0],
          },
          targetRoute: {
            name: "view-rent-detail",
            params: [
              {
                key: "id",
                value: rentRef.value.id,
              },
            ],
          },
        });
        await store.dispatch("app/unlockUI");
      },
      paymentType: computed(() => {
        switch (rentRef.value.state) {
          case "UNPAID":
          case "PENDING_FOR_CHANGE_PAYMENT_METHOD":
            return "INITIAL";
          case "PENDING":
          case "IN_PROCESS":
            return "FOR_EXTRA_DAYS";
          case "DEPOSIT_PENDING":
            return "FOR_DEPOSIT";
        }
        return "FOR_DELAY_RETURN";
      }),
    };
  },
});
</script>

<style scoped></style>
