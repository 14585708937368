<template>
  <n-divider title-placement="left" v-if="!hideTitle">{{
    t("rent.rangeOfDates.title")
  }}</n-divider>
  <n-grid cols="1 s:4" responsive="screen" class="pb-4"
    ><n-gi span="2" offset="0 s:1"
      ><date-selector
        :publication="publication"
        v-model:value="model"
        :fixedStart="fixedStart !== null"
        :minDate="fixedStart"
        :startDate="fixedStart"
        :auto-range="minDays"
        :disabled="disabled"
        :ignoreRentId="ignoreRentId" /></n-gi
  ></n-grid>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import DateSelector from "@/components/publication/DateSelector/Index.vue";
import { addDays } from "date-fns";
import { NDivider, NGrid, NGi } from "naive-ui";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RangeDatesSelector",
  components: { DateSelector, NDivider, NGrid, NGi },
  props: {
    publication: { type: Object, required: true },
    fixedStart: { type: Date, default: null },
    value: { type: Object },
    disabled: { type: Boolean, default: false },
    minDays: { type: Number, default: null },
    ignoreRentId: { type: String, default: null },
    hideTitle: { type: Boolean, default: false },
  },
  emits: ["update:value", "rangechanged"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const rangeRef = ref(props.value);

    onMounted(() => {
      if (props.fixedStart) {
        rangeRef.value = {
          start: addDays(props.fixedStart, 1),
          end: rangeRef.value?.end || addDays(props.fixedStart, 3),
        };
      } else if (rangeRef.value) {
        rangeRef.value = {
          start: rangeRef.value.start,
          end: rangeRef.value.end,
        };
      }
    });

    watch(rangeRef, async (newValue) => {
      emit("update:value", newValue);
      emit("rangechanged", newValue);
    });
    return {
      t,
      model: rangeRef,
    };
  },
});
</script>
